// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "c676453e0683fe9c83aee9186be6e5376dbe2b4d",
  version: "1.102.4",
};
